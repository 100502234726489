/**=====================
    14. Chat CSS Start
========================**/
.chat-box {
  .chat-menu {
    max-width: 340px;
  }
  .chat-menu {
    .people-list {
      .search {
        position: relative;
        .theme-form {
          i {
            position: absolute;
            right: 10px;
            top: 16px;
            font-size: 14px;
            color: $theme-medium-color;
          }
        }
      }
    }
  }
  .people-list {
    .search {
      .form-control {
        border: 1px solid $light-gray;
        text-transform: capitalize;
        border-radius: 10px;
        &::placeholder {
          color: $semi-dark;
        }
      }
      .input-group-text {
        background-color: transparent;
        i {
          font-size: 14px;
          color: $light-text;
        }
      }
    }
    ul {
      padding: 0;
      li {
        padding-bottom: 20px;
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
  .user-image {
    float: left;
    width: 52px;
    height: 52px;
    margin-right: 5px;
  }
  .about {
    float: left;
    margin-top: 5px;
    padding-left: 10px;
    .name {
      color: $dark-color;
      letter-spacing: 1px;
      font-weight: 600;
      a {
        color: $theme-body-font-color;
      }
    }
  }
  .status {
    color: $light-text;
    letter-spacing: 1px;
    font-size: 12px;
    margin-top: 5px;
    .chat-status {
      font-weight: 600;
      color: $theme-body-font-color;
    }
    p {
      font-size: 14px;
    }
  }
  .chat-right-aside {
    .chat {
      .chat-header {
        padding: 15px;
        border-bottom: 1px solid $light-gray;
        img {
          float: left;
          width: 50px;
          box-shadow: 1px 1px 4px 1px $light-gray;
        }
        .chat-menu-icons {
          margin-top: 15px;
          margin-left: auto;
          display: flex;
          justify-content: flex-end;
          li {
            margin-right: unset;
            a i,
            svg {
              color: $theme-body-sub-title-color;
              font-size: 25px;
              cursor: pointer;
            }
            &:nth-child(n + 2) {
              margin-left: 24px;
            }
          }
        }
      }
      .chat-msg-box {
        padding: 20px;
        overflow-y: auto;
        height: 560px;
        margin-bottom: 90px;
        .chat-user-img {
          margin-top: -35px;
        }
        .message-data {
          margin-bottom: 10px;
        }
        .message-data-time {
          letter-spacing: 1px;
          font-size: 12px;
          color: $semi-dark;
          font-weight: 600;
        }
        .message {
          color: $light-text;
          padding: 20px;
          line-height: 1.9;
          letter-spacing: 1px;
          font-size: 14px;
          margin-bottom: 30px;
          width: 50%;
          position: relative;
        }
        .my-message {
          border: 1px solid $light-gray;
          border-radius: 10px;
          border-top-left-radius: 0;
        }
        .other-message {
          border: 1px solid $light-gray;
          border-radius: 10px;
          border-top-right-radius: 0;
        }
      }
      .chat-message {
        padding: 20px;
        border-top: 1px solid $light-body-bg-color;
        position: absolute;
        left: 15px;
        width: calc(100% - 30px);
        bottom: 0;
        .smiley-box {
          background: $light-semi-gray;
          padding: 10px;
          display: block;
          border-radius: 4px;
          margin-right: 0.5rem;
        }
        .text-box {
          position: relative;
          .input-txt-bx {
            height: 50px;
            border: 2px solid $primary-color;
            padding-left: 18px;
            font-size: 13px;
            letter-spacing: 1px;
          }
          i {
            position: absolute;
            right: 20px;
            top: 20px;
            font-size: 20px;
            color: $light-gray;
            cursor: pointer;
          }
          .btn {
            font-size: 16px;
            font-weight: 500;
          }
        }
      }
    }
  }
  .chat-menu {
    right: 10px;
    opacity: 0;
    transform: translateY(-30px);
    visibility: hidden;
    top: 95px;
    position: absolute;
    z-index: 3;
    background-color: $white;
    border: 1px solid $light-gray;
    transition: all linear 0.3s;
    padding-right: unset;
    padding-left: unset;
    .tab-pane {
      padding: 0 15px;
    }
    ul {
      li {
        .about {
          .status {
            i {
              font-size: 10px;
            }
          }
        }
      }
    }
    .user-profile {
      margin-top: 30px;
      .user-content {
        h5 {
          margin: 15px 0;
          font-weight: 600;
          a {
            color: $theme-body-font-color;
          }
        }
        p {
          font-size: 14px;
        }
        .social-list {
          li {
            display: inline-block;
            a {
              width: 30px;
              height: 30px;
              border-radius: 5px;
              background-color: rgba($primary-color, 0.08);
              color: $primary-color;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            &:hover {
              a {
                background-color: $primary-color;
                color: $white;
              }
            }
            &:nth-child(n + 2) {
              margin-left: 5px;
            }
          }
        }
      }
      .image {
        position: relative;
        .icon-wrapper {
          position: absolute;
          bottom: 0;
          left: 55%;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 30px;
          width: 30px;
          border-radius: 50%;
          background-color: $white;
          cursor: pointer;
          overflow: hidden;
          margin: 0 auto;
          font-size: 14px;
          box-shadow: 1px 1px 3px 1px $light-gray;
          i {
            color: $primary-color;
          }
        }
        .avatar {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          overflow: hidden;
          margin-left: auto;
          margin-right: auto;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            border: 5px solid $light-gray;
          }
        }
      }
      .border-right {
        border-right: 1px solid $light-gray;
      }
      .follow {
        margin-top: 15px;
        padding-top: 15px;
        border-top: 1px solid $light-gray;
        .follow-num {
          font-size: 20px;
          color: $black;
          font-weight: 600;
          line-height: 1;
        }
        span {
          color: $light-text;
          font-size: 14px;
          letter-spacing: 1px;
          font-weight: 600;
        }
      }
      .digits {
        border-top: 1px solid $light-gray;
        margin-top: 15px;
        padding-top: 15px;
        p {
          margin-bottom: unset;
        }
      }
      .social-media a {
        color: $semi-dark;
        font-size: 15px;
        padding: 0 7px;
      }
      .chat-profile-contact {
        p {
          font-size: 14px;
          color: $light-text;
        }
      }
    }
    .nav {
      margin-bottom: 20px;
    }
    .nav-tabs .nav-item {
      width: 33.33%;
      a {
        padding: 15px !important;
        color: $semi-dark !important;
        letter-spacing: 1px;
        font-size: 14px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .material-border {
        border-width: 1px;
        border-color: $primary-color;
      }
      .nav-link.active {
        color: $primary-color !important;
      }
    }
    &.show {
      opacity: 1;
      visibility: visible;
      transform: translateY(0px);
      transition: all linear 0.3s;
      padding-bottom: 25px;
      position: absolute;
      top: 70px;
    }
  }
  .chat-history {
    .call-content {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 650px;
      height: 100%;
      > div {
        width: 100%;
        height: 100%;
        padding: 30px;
      }
    }
    .total-time {
      h2 {
        font-size: 50px;
        font-weight: 700;
        margin-bottom: 30px;
        color: $light-text;
      }
    }
    .receiver-img {
      margin-top: 55px;
      img {
        border-radius: 5px;
      }
    }
    .call-icons {
      margin-bottom: 35px;
      ul {
        display: flex;
        align-items: center;
        justify-content: center;
        li {
          width: 55px;
          height: 55px;
          border: 1px solid $light-gray;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          + li {
            margin-left: 10px;
          }
          a {
            color: #999;
            font-size: 22px;
            margin-top: 3px;
          }
        }
      }
    }
    .caller-img {
      height: 100%;
      border-bottom-right-radius: 10px;
    }
  }
}
.chat-left-aside {
  > .d-flex {
    margin-bottom: 15px;
  }
  .people-list {
    height: 635px;
  }
  ul li {
    position: relative;
  }
}
.status-circle {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 4px;
  left: 44px;
  border-radius: 50%;
  border: 2px solid $white;
}
.away {
  background-color: $warning-color;
}
.online {
  background-color: $success-color;
}
.offline {
  background-color: $danger-color;
}
.call-chat-sidebar {
  max-width: 320px;
}
.call-chat-sidebar,
.chat-body {
  .card {
    .card-body {
      padding: 15px;
    }
  }
}
.chart-widget {
  .chart-widget-top {
    h4 {
      font-weight: 600;
    }
    .num {
      i {
        margin-left: 5px;
      }
    }
  }
}
.apexcharts-menu {
  border-color: $light-gray !important;
}
@media (max-width: 1366px) {
  .chat-box {
    .chat-right-aside {
      .chat {
        .chat-message {
          bottom: 0;
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) and (min-width: 420px) {
  .status-circle {
    top: 6px;
    left: 46px;
  }
}
@media (max-width: 360px) {
  .chat-box {
    .chat-menu {
      width: 250px;
    }
  }
}
/**=====================
    14. Chat CSS Ends
==========================**/
