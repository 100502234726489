/**=====================
    83. Map start
==========================**/
.jvector-map-height {
  height: 389px;
  @media only screen and (max-width: 1741px) {
    height: 385px;
  }
  @media only screen and (max-width: 1661px) {
    height: 367px;
  }
  .geographies-map {
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 100%;

    path {
      fill: rgba(232, 238, 252, 0.8);
      stroke: $white;
    }
  }
}
