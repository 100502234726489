/*! -----------------------------------------------------------------------------------

    Template Name: Enzo Admin
    Template URI: http://admin.pixelstrap.com/enzo/template
    Description: This is Admin template
    Author: Pixelstrap
    Author URI: https://themeforest.net/user/pixelstrap

-----------------------------------------------------------------------------------
 */
// *** utils ***
@import 'utils/variables';
// @import 'utils/theme-font';
:root {
  --theme-default: #3e5fce;
  --theme-secondary: #ffce00;
}

// *** base ***
@import 'base/reset';
@import 'base/typography';

//  *** components ***
@import 'components/according';
@import 'components/alert';
@import 'components/avatars';
@import 'components/badge';
@import 'components/breadcrumb';
@import 'components/buttons';
@import 'components/card';
// @import 'components/color';
@import 'components/datatable';
@import 'components/datepicker';
@import 'components/dropdown';
@import 'components/radial-progress';
@import 'components/form-input';
@import 'components/form-wizard';
@import 'components/forms';
@import 'components/icons';
@import 'components/list';
@import 'components/loader';
@import 'components/map';
@import 'components/popover';
@import 'components/print';
@import 'components/radio';
@import 'components/react-plugin';
@import 'components/ribbon';
@import 'components/switch';
@import 'components/tab';
@import 'components/table';
@import 'components/todo';
@import 'components/touchspin';
@import 'components/tour';
@import 'components/tree';
@import 'components/typeahead-search';
@import 'components/scrollbar';
@import 'components/whether-icon';

//	*** pages ***

@import 'pages/blog';
@import 'pages/bookmark-app';
@import 'pages/cart';
@import 'pages/chart';
@import 'pages/chat';
@import 'pages/checkout';
@import 'pages/comingsoon';
@import 'pages/contacts';
@import 'pages/dashboard_default';
@import 'pages/dashboard_2';
@import 'pages/general-widget';
@import 'pages/ecommerce';
@import 'pages/email-application';
@import 'pages/errorpage';
@import 'pages/faq';
@import 'pages/file';
@import 'pages/gallery';
@import 'pages/job-search';
@import 'pages/jsgrid';
@import 'pages/kanban';
@import 'pages/knowledgebase';
@import 'pages/language';
@import 'pages/learning';
@import 'pages/login';
@import 'pages/megaoption';
@import 'pages/order-history';
@import 'pages/page';
@import 'pages/pricing';
@import 'pages/progress';
@import 'pages/projectlist';
@import 'pages/social-app';
@import 'pages/task';
@import 'pages/timeline-v';
@import 'pages/timeliny';
@import 'pages/user-profile';
@import 'pages/wishlist';
@import 'pages/invoice';

// *** layout ***

@import 'layout/footer';
@import 'layout/grid';
@import 'layout/header';
@import 'layout/navs';
@import 'layout/search';
@import 'layout/select2';
@import 'layout/sidebar';
@import 'layout/rtl';
@import 'layout/box-layout';

/* ---------------------
	*** themes *** 
-----------------------*/
@import 'themes/dark';
@import 'themes/dark-sidebar';
@import 'themes/theme-customizer';
@import 'themes/update';