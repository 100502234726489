/**=====================
    09. Error-page CSS Start
==========================**/
.error-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
  background-color: $white;
  &.maintenance-bg {
    // background-image: url(../../images/other-images/maintenance-bg.jpg);
    background-color: rgba(255, 255, 255, 0.6);
    background-blend-mode: overlay;
  }
  svg.maintanance {
    height: 420px;
  }
  svg {
    width: 100%;
    height: 380px;
  }
  .maintenance-heading {
    margin-top: 20px;
    .headline {
      font-size: 99px;
      font-weight: 900;
      letter-spacing: 10px;
      color: $primary-color;
      z-index: 2;
      position: relative;
    }
  }
  .error-heading {
    margin-top: 115px;
    .headline {
      font-size: 285px;
      font-weight: 700;
      letter-spacing: 1px;
      margin-top: -130px;
    }
  }
  .error {
    margin: 70px 0 100px;
    color: $black;
    .error-title {
      font-size: 9.5rem;
      color: $white;
      text-shadow: 6px 6px 7px rgba(0, 0, 0, 0.32);
    }
    .line-bottom {
      width: 10%;
      height: 5px;
      background-color: $warning-color;
      border: none;
    }
    .c-white {
      color: $white;
    }
    .error-btn {
      text-align: center;
    }
  }
  .svg-wrraper {
    margin-bottom: 35px;
    svg {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      height: 440px;
    }
  }
  h3 {
    font-weight: 700;
    margin-bottom: 20px;
  }
  .sub-content {
    font-size: 18px;
    color: $theme-body-font-color;
    letter-spacing: 1px;
    line-height: 35px;
    font-weight: normal;
    z-index: 3;
    margin-bottom: 35px;
  }
}
@media (max-width: 1199px) {
  .error-wrapper {
    svg {
      height: 320px;
    }
  }
}
@media (max-width: 991px) {
  .error-wrapper {
    svg {
      height: 280px;
    }
    .svg-wrraper {
      svg {
        height: 450px;
      }
    }
    .sub-content {
      line-height: 25px;
    }
  }
}
@media (max-width: 767px) {
  .error-wrapper {
    h3 {
      margin-bottom: 18px;
    }
    .sub-content {
      margin-bottom: 30px;
      font-size: 16px;
    }
  }
}
@media (max-width: 575px) {
  .error-wrapper {
    .svg-wrraper {
      margin-bottom: 25px;
      svg {
        height: 380px;
      }
    }
    .sub-content {
      font-size: 14px;
    }
  }
}
@media (max-width: 480px) {
  .error-wrapper {
    .svg-wrraper {
      svg {
        height: auto;
      }
    }
  }
}
/**=====================
    09. Error-page CSS Ends
==========================**/
