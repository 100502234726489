/**=====================
    78. Sidebar CSS Start
==========================**/
%sidebar-after {
  position: absolute;
  content: '';
  top: 48%;
  width: 6px;
  border-top: 2px solid $white;
}

%horizontal-after {
  position: absolute;
  content: '';
  top: 49%;
  width: 5px;
  height: 1px;
  border-radius: 100%;
  background-color: #59667a;
}

%common {
  border-left-color: $dark-color;
  transition: $sidebar-transition;
}

%for-animated-hover {
  box-shadow: $main-header-shadow;
  transform: translateY(30px);
  opacity: 0;
  visibility: hidden;
  left: 0;
}

%for-animated-hover-box {
  opacity: 1;
  transform: translateY(0px);
  visibility: visible;
  border-radius: 5px;
  overflow: hidden;

  &:before {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid $white;
    content: '';
    top: -7px;
    position: absolute;
    left: 10px;
    z-index: 2;
  }

  &:after {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid $light-background;
    content: '';
    top: -7px;
    position: absolute;
    left: 10px;
    z-index: 1;
  }
}

%sidebar-icon {
  display: block;
  transform: rotateX(0deg);
  z-index: 1;
  background-color: $white;
  color: $light-background;
  font-size: 14px;
  opacity: 1;
  margin-top: -70px;
  padding: 10px 0;
  text-align: left;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
  position: absolute;
  width: 245px;
  margin-left: 115px;
}

.page-wrapper {
  position: relative;

  .page-header {
    max-width: 100vw;
    position: $main-header-position;
    top: $main-header-top;
    z-index: $main-header-z-index;
    transition: $sidebar-transition;
    background-color: $white;
    box-shadow: $main-header-shadow;

    .header-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: $header-wrapper-padding;
      position: relative;

      .search-full {
        background-color: $white;
        position: absolute;
        right: 0;
        z-index: 1;
        height: 0;
        width: 0;
        transform: scale(0);
        transition: all 0.3s ease;

        &.open {
          height: 100%;
          width: 100%;
          animation: zoomIn 0.5s ease-in-out;
          transform: scale(1);
          transition: all 0.3s ease;
        }

        input {
          line-height: 65px;
          padding-left: 60px;
          width: 100% !important;

          &:focus {
            outline: none !important;
          }
        }

        .form-group {
          .close-search {
            position: absolute;
            font-size: 15px;
            right: 30px;
            top: 30px;
            color: $dark-gray;
            cursor: pointer;
          }

          &:before {
            position: absolute;
            left: 30px;
            top: 27px;
            content: '\f002';
            font-size: 16px;
            color: $dark-gray;
            font-family: FontAwesome;
          }
        }
      }

      .nav-right {
        justify-content: flex-end;

        >ul {
          >li {
            width: 40px;
            height: 40px;
            border-radius: 20px;
            background-color: $body-color;
            display: flex !important;
            align-items: center;
            justify-content: center;

            i {
              font-size: 16px;
            }

            &.profile-nav {
              &:hover {
                i.middle {
                  color: $light-text;
                }
              }
            }

            &:hover {
              background-color: $primary-color;

              i,
              svg {
                // color: $white;
                // stroke: $white;
                color: black;
                stroke: black;
              }

              .profile-dropdown {
                li {
                  svg {
                    stroke: $theme-body-font-color;
                  }
                }
              }
            }

            &:last-child {
              width: auto;
              height: auto;
              border-radius: unset;
              background-color: transparent;
            }

            .profile-media {
              &:hover {
                i {
                  color: $light-text;
                }
              }
            }
          }
        }

        ul {
          li {
            svg {
              vertical-align: $header-wrapper-nav-icon-align;
              width: $header-wrapper-nav-icon-size;

              path {
                color: $header-wrapper-nav-icon-color;
              }
            }

            .d-flex {
              img {
                border-radius: 50%;
              }
            }
          }
        }

        &.right-header {
          ul {
            li {
              i {
                font-size: 18px;
                transition: all 0.3s ease;
                vertical-align: middle;

                &.fa-lightbulb-o {
                  font-weight: 500;
                  font-size: 22px;
                  transition: all 0.3s ease;
                  animation: zoomIn 300ms ease-in-out;
                }
              }

              .mode {
                cursor: pointer;
                width: 20px;
                text-align: center;
              }

              .header-search {
                cursor: pointer;
              }

              .profile-media {
                .flex-grow-1 {
                  margin-left: 15px;

                  span {
                    font-weight: 500;
                  }

                  p {
                    font-size: 12px;
                    line-height: 1;
                    color: $light-text;
                  }
                }
              }
            }
          }
        }

        >ul {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          >li {
            padding: 6px 10px;
            margin-right: 20px;
            display: inline-block;
            position: relative;

            .badge {
              position: absolute;
              right: -10px;
              top: -10px;
              padding: 2px 4px;
              font-size: 11px;
              font-weight: 700;
            }

            span {
              ul {
                left: inherit;
                right: -10px;
                width: 130px;

                &:before,
                &:after {
                  left: inherit;
                  right: 10px;
                }

                li {
                  display: block;

                  a {
                    font-size: 14px;
                    color: $dark-color;

                    i {
                      margin-left: 10px;
                      font-size: 13px;
                      color: $dark-color;
                    }
                  }
                }
              }
            }
          }

          .flag-icon {
            font-size: 16px;
          }
        }

        .bookmark-flip {
          width: unset !important;
          box-shadow: unset !important;
          background-color: transparent !important;
          overflow: initial;
        }

        .flip-card {
          width: 220px;
          height: 245px;
          background-color: transparent;

          .flip-card-inner {
            position: relative;
            height: 100%;
            text-align: center;
            transition: transform 0.6s;
            transform-style: preserve-3d;

            .front,
            .back {
              position: absolute;
              backface-visibility: hidden;
              border-radius: 10px;
              overflow: hidden;
              box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
              width: 300px;
              height: auto;

              button {
                background-color: $transparent-color;
                // color: $white;
                color: black;
                border: none;

                &:focus {
                  outline: $transparent-color;
                }
              }
            }

            .front {
              background-color: $white;
              transition: 0.3s;

              &.dropdown-title {
                >svg {
                  position: absolute;
                  width: 50px;
                  height: 50px;
                  top: 0;
                  right: 0;
                  opacity: 0.05;
                  stroke: $primary-color;
                }
              }

              svg {
                stroke: $dark-color;
                height: 18px;
                opacity: 1;
                position: static;
                -webkit-transform: none;
                -webkit-transform: initial;
                transform: none;
                width: 18px;

                &:hover {
                  // stroke: $white;
                  stroke: black;
                  transition: all 0.3s ease;
                }
              }
            }

            .back {
              background-color: $white;
              transform: rotateY(180deg);

              li {
                &:first-child {
                  margin-top: 0;
                  height: 160px !important;
                }

                &:last-child {
                  padding: 15px 0;
                  border-top: 1px solid #ddd;
                }
              }

              button {
                width: 100%;
              }

              .flip-back-content {
                input {
                  border-radius: 25px;
                  padding: 6px 12px;
                  width: 100%;
                  border: 1px solid #ddd;
                }
              }
            }

            &.flipped {
              transform: rotateY(180deg);

              .front {
                opacity: 0;
              }
            }
          }
        }

        .onhover-show-div:not(.profile-dropdown) {
          li {
            &:first-child {
              padding: 20px;
              background-color: transparent;

              h6 {
                color: $primary-color;
              }

              svg {
                position: absolute;
                width: 50px;
                height: 50px;
                top: 0;
                right: 0;
                opacity: 0.05;
                stroke: $primary-color;
              }
            }
          }
        }

        .onhover-show-div {
          width: 330px;
          top: 50px;
          right: 0;
          left: unset;

          li {
            padding: 15px;

            p {
              opacity: 0.6;
            }

            &+li {
              padding-top: 15px;
              padding-bottom: 0px;
              border-top: 1px solid $light-gray;
            }

            &:last-child {
              padding-bottom: 15px;
            }

            .d-flex {
              position: relative;
              align-items: flex-start;

              img {
                width: 40px;
                position: relative;
              }

              .status-circle {
                left: 0;
              }

              .flex-grow-1 {
                a {
                  color: unset;
                }

                >span {
                  letter-spacing: 0.8px;
                  padding-right: 10px;
                  display: inline-block;
                }

                p {
                  margin-bottom: 15px;
                }
              }
            }
          }

          &:before,
          &:after {
            right: 10px !important;
            left: unset !important;
          }
        }

        .notification-box {
          position: relative;

          svg {
            animation: tada 1.5s ease infinite;
          }
        }

        .cart-box {
          position: relative;

          svg {
            animation: swing 1.5s ease infinite;
          }
        }

        .cart-dropdown {
          li {
            .d-flex {
              align-items: center;
            }

            &+li {
              padding-bottom: 10px;
            }
          }

          p {
            line-height: 1.3;
            margin-top: 3px;
            opacity: 0.6;
          }

          .close-circle {
            position: absolute;
            right: 0;
            top: 0;

            svg {
              width: 14px;
            }
          }

          .qty-box {
            .input-group {
              width: 80px;

              .btn {
                padding: 0px 2px;
              }
            }
          }
        }

        .notification-dropdown {
          top: 52px;
          width: 300px;
          right: -20px;
          left: unset;

          li {
            &+li {
              padding-top: 8px;

              p {
                margin-bottom: 8px;
              }
            }

            &:last-child {
              text-align: center;
              border-top: 1px solid #ecf3fa;
            }

            p {
              span {
                color: $light-font;
              }
            }

            a {
              p {
                color: $theme-body-font-color;
              }
            }
          }

          &:before,
          &:after {
            right: 28px !important;
            left: unset !important;
          }
        }

        .profile-dropdown {
          width: 160px;
          top: 57px;
          left: -12px;
          padding: 0 10px;

          li {
            padding: 10px !important;

            &:hover {
              a {
                span {
                  transition: color 0.3s ease;
                }

                svg {
                  transition: stroke 0.3s ease;

                  path {
                    transition: stroke 0.3s ease;
                  }
                }
              }
            }

            &:last-child {
              padding-top: 10px !important;
            }

            span {
              color: $dark-color;
            }

            svg {
              width: 16px;
              height: 24px;
              vertical-align: bottom;
              margin-right: 10px;
              stroke: $theme-body-font-color;
            }
          }
        }
      }
    }
  }

  .page-body-wrapper {
    background-color: $body-color;

    .page-body {
      min-height: calc(100vh - #{$header-size});
      margin-top: $header-size;
      padding: $page-body-padding;
      position: relative;
      transition: 0.5s;

      &:before {
        animation: fadeIn 0.5s ease-in-out;
      }
    }

    .page-title {
      padding-bottom: $page-title-padding;

      >.row {
        .col-6 {
          &:first-child {
            display: flex;

            h3 {
              padding-right: 20px;
            }
          }
        }
      }

      .row {
        align-items: center;

        h3 {
          font-size: $page-title-font-size;
          margin-bottom: $page-title-margin-bottom;
          text-transform: $page-title-text-tranform;

          small {
            display: $page-small-title-display;
            font-size: $page-small-title-font-size;
            margin-bottom: $page-small-title-margin-bottom;
            text-transform: $page-small-title-text-transform;
            color: $theme-body-sub-title-color;
          }
        }
      }

      .breadcrumb {
        background-color: $transparent-color;
        padding: 0;
        margin-bottom: 0;
        align-items: center;
        display: flex;
        justify-content: flex-end;

        li {
          font-size: 14px;
          letter-spacing: 1px;
          position: relative;
          color: $theme-body-font-color;
          background-color: transparent;
        }

        .breadcrumb-item {
          &+.breadcrumb-item {
            &:before {
              content: '';
              font-family: $font-awesome;
              color: $theme-body-font-color;
              // background-image: $breadcrumb-content;
              background-size: 16px 20px;
              background-repeat: no-repeat;
              width: 20px;
              height: 16px;
            }
          }

          a {
            color: $theme-body-font-color;

            svg {
              width: 16px;
              height: 16px;
              vertical-align: text-top;
              color: $theme-body-font-color;
            }
          }
        }
      }
    }
  }

  &.horizontal-wrapper {
    [data-simplebar] {
      position: relative;
    }

    .sidebar-wrapper {
      .sidebar-main {
        .sidebar-links {
          li.menu-box {
            ul {
              li {
                &:hover {
                  li {
                    &:hover {
                      a {
                        &:hover {
                          &::after {
                            background-color: $light-text;
                          }
                        }
                      }
                    }
                  }

                  .sidebar-submenu {
                    opacity: 1;
                    visibility: visible;
                    margin-top: 6px;

                    li {
                      a {

                        &.active,
                        &:hover {
                          &::after {
                            background-color: $light-text;
                          }
                        }

                        &::after {
                          background-color: $light-text;
                        }

                        span {
                          color: $light-text;
                        }
                      }
                    }
                  }
                }

                &.active {
                  a.active {
                    ~.sidebar-submenu {
                      opacity: 1;
                      visibility: visible;
                      margin-top: 6px;

                      li {
                        a {

                          &.active,
                          &:hover {
                            &::after {
                              background-color: $light-text;
                            }

                            span {
                              color: $primary-color;
                            }
                          }

                          &::after {
                            background-color: $light-text;
                          }

                          span {
                            color: $light-text;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .footer {
      margin-left: 0;

      &.footer-fix {
        width: calc(100% - 0px);
      }
    }

    .header-logo-wrapper {
      width: 150px;

      .toggle-sidebar {
        display: none;
      }
    }

    .left-header {
      padding-right: 0;
    }

    .page-body-wrapper {
      li {
        display: inline-block;
      }

      .file-sidebar {
        ul {
          li {
            display: block;
          }
        }
      }

      .sidebar-wrapper {
        height: 54px;
        line-height: inherit;
        top: 93px;
        background-color: $primary-color;
        box-shadow: 0px 9px 24px rgba($semi-dark, 0.05);
        overflow-x: hidden;
        z-index: 2;
        overflow-y: visible;
        position: fixed;
        width: 100vw;
        border-top: 1px solid $light-semi-gray;

        .logo-wrapper,
        .logo-icon-wrapper {
          display: none;
        }

        .sidebar-main {
          text-align: left;
          position: relative;

          .left-arrow,
          .right-arrow {
            position: absolute;
            top: 0px;
            padding: 15px;
            background-color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
            cursor: pointer;

            svg {
              height: 24px;
              width: 24px;
            }

            &.disabled {
              display: none;
            }
          }

          .left-arrow {
            left: 0;
          }

          .right-arrow {
            right: 0;
          }

          .sidebar-links {
            list-style: none;
            margin: 0;
            padding: 0;
            line-height: normal;
            direction: ltr;
            text-align: left;
            display: -webkit-box !important;
            padding: 0 5px;
            transition: all 0.5s ease;
            white-space: nowrap;

            li {
              &.sidebar-list {
                a {
                  &.active {
                    .according-menu {
                      i {
                        &:before {
                          content: '-';
                        }
                      }
                    }
                  }

                  .according-menu {
                    display: none;
                  }
                }

                >ul,
                .sidebar-submenu {
                  li {
                    a {

                      &.active,
                      &:hover {
                        // color: $white;
                        color: black;

                        &:after {
                          background-color: $white;
                        }
                      }
                    }
                  }
                }
              }
            }

            >li {
              &:first-child {
                display: none !important;
              }
            }

            .menu-box {
              >ul {
                >li {
                  position: relative;
                  padding-top: 10px;
                  padding-bottom: 10px;

                  &:hover {
                    >a {
                      >span {
                        // color: $white;
                        color: black;
                        transition: all 0.3s ease;
                      }

                      &:after {
                        left: 0;
                        right: 0;
                        opacity: 1;
                      }
                    }

                    a {
                      background-color: lighten($primary-color, 10%);
                      transition: all 0.3s ease;

                      svg {
                        fill: rgba($primary-color, 0.1%);
                        // stroke: $white;
                        stroke: black;
                        transition: all 0.3s ease;
                      }
                    }
                  }

                  >a {
                    display: block;
                    // color: $white;
                    color: black;
                    line-height: 23px;
                    text-decoration: none;
                    text-transform: capitalize;
                    padding: 5px 15px;
                    margin: 0 3px;
                    letter-spacing: 0.04em;
                    position: relative;
                    border-radius: 5px;
                    font-weight: 500;
                    transition: all 0.3s ease;

                    &.active {
                      background-color: lighten($primary-color, 22%);
                      transition: all 0.3s ease;

                      svg,
                      span {
                        // color: $white;
                        color: black;
                        transition: all 0.3s ease;
                      }
                    }

                    .badge {
                      position: absolute;
                      top: -5px;
                      margin-left: 1px;
                      right: 10px;
                      padding: 0.2em 0.7em;
                    }

                    svg {
                      width: 16px;
                      vertical-align: bottom;
                      margin-right: 10px;
                      stroke-width: 2.5px;
                    }
                  }

                  >ul,
                  .sidebar-submenu {
                    position: fixed;
                    z-index: 4;
                    box-shadow: 0 0 1px 0 $dark-gray;
                    padding: 15px 0px 15px 0px;
                    background-color: $white;

                    li {
                      border: 0;
                      float: none;
                      position: relative;
                      display: block;

                      a {
                        background: transparent;
                        padding: 4px 30px;
                        display: block;
                        transition: all 0.5s ease;
                        line-height: 1.9;
                        // color: $white;
                        color: black;
                        letter-spacing: 0.06em;
                        font-weight: 500;
                        transition: all 0.3s ease;

                        &:after {
                          left: 20px;
                          @extend %horizontal-after;
                        }

                        &:hover {
                          // color: $white;
                          color: black;
                          letter-spacing: 1.5px;
                          transition: all 0.3s ease;

                          &:after {
                            background-color: $white;
                          }
                        }
                      }
                    }
                  }

                  .mega-menu-container {
                    position: fixed;
                    right: 0;
                    left: 0;
                    margin: 0 auto;
                    z-index: 4;
                    box-shadow: 0 0 1px 0 $dark-gray;
                    padding: 30px;
                    background-color: $white;
                    width: 95%;
                    border-radius: 4px;
                    display: block !important;

                    .mega-box {
                      width: 20%;

                      .link-section {
                        .submenu-title {
                          h5 {
                            text-transform: uppercase;
                            font-size: 14px;
                            margin-left: -13px;
                          }
                        }

                        .submenu-content {
                          box-shadow: none;
                          background-color: transparent;
                          position: relative;
                          display: block;
                          padding: 0;

                          li {
                            border: 0;
                            float: none;
                            position: relative;
                            display: block;

                            a {
                              border: 0 !important;
                              background: transparent;
                              color: $theme-body-font-color;
                              border-radius: 0 !important;
                              text-transform: capitalize;
                              padding: 3px 0;
                              font-size: 13px;
                              display: block;
                              letter-spacing: 0.07em;
                              line-height: 1.9;
                              position: relative;
                              transition: all 0.3s ease;

                              &:hover {
                                color: $primary-color;
                                letter-spacing: 1.5px;
                                transition: all 0.3s ease;

                                &:after {
                                  // color: $white;
                                  color: black;
                                  transition: all 0.3s ease;
                                }
                              }

                              &:after {
                                position: absolute;
                                content: '';
                                left: -12px;
                                top: 50%;
                                width: 5px;
                                height: 1px;
                                background-color: $light-semi-font;
                              }
                            }
                          }
                        }
                      }
                    }
                  }

                  &.mega-menu {
                    position: unset;

                    &:hover {
                      .mega-menu-container {
                        opacity: 1;
                        visibility: visible;
                        margin-top: 30px;
                      }
                    }
                  }

                  &.hoverd {

                    >ul,
                    .sidebar-submenu {
                      opacity: 1;
                      visibility: visible;
                      margin-top: 8px;
                      transition: all 0.5s ease;

                      li {
                        a {
                          color: $light-text;

                          &:after {
                            background-color: $light-text;
                          }
                        }
                      }
                    }

                    .mega-menu-container {
                      opacity: 1;
                      visibility: visible;
                      margin-top: 20px;
                      transition: all 0.5s ease;
                    }
                  }
                }
              }
            }
          }
        }

        &.overlay-white {
          background-color: rgba(255, 255, 255, 0.08);
        }
      }

      .toggle-nav {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;

        i {
          font-size: 24px;
        }
      }

      .page-body {
        margin-top: 175px;
      }

      .sidebar-main-title {
        display: none !important;
      }

      &.scorlled {
        .sidebar-wrapper {
          display: none;
          transition: all 0.3s ease;
        }
      }
    }

    .page-header {
      width: 100%;
      box-shadow: none;

      .header-wrapper {
        .form-control-plaintext {
          width: auto !important;
        }
      }
    }

    //dashboard
    .bestselling-sec {
      ul {
        &.selling-list {
          li {
            display: flex;
          }
        }
      }
    }
  }

  .sidebar-main-title {
    margin-bottom: 20px;

    h6 {
      font-size: 18px;
      // color: $white;
      color: black;
      font-weight: 500;
      font-family: $font-rubik;
    }
  }

  // left sidebar css
  &.compact-wrapper {
    .page-header {
      margin-left: 320px;
      width: calc(100% - 320px);

      &.close_icon {
        margin-left: 106px;
        width: calc(100% - 104px);
      }

      .header-wrapper {
        .logo-wrapper {
          display: none;
        }

        .toggle-sidebar {
          display: none;
        }
      }
    }

    .page-body-wrapper {
      div {
        &.sidebar-wrapper {
          position: fixed;
          top: 0;
          z-index: 9;
          height: 100%;
          line-height: inherit;
          // background-color: $primary-color;
          background-color: white;
          width: 320px;
          text-align: left;
          transition: 0.3s;
          box-shadow: 0 0 21px 0 rgba($semi-dark, 0.1);

          .logo-wrapper,
          .logo-icon-wrapper {
            padding: 14px 10px;
            border-bottom: 1px solid rgba($white, 0.07);
          }

          .logo-icon-wrapper {
            display: none;
          }

          &.close_icon {
            width: auto;

            // transition: all 0.3s ease;
            &:hover {
              .sidebar-main {
                .sidebar-links {
                  .menu-box {
                    >ul {
                      >li {
                        a {
                          svg {
                            margin-right: 0px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .sidebar-main {
              .sidebar-links {
                .menu-box {
                  >ul {
                    >li {
                      a {
                        svg {
                          margin-right: 0;
                        }
                      }
                    }
                  }
                }
              }
            }

            &:hover {
              .sidebar-main {
                .sidebar-links {
                  .sidebar-list {
                    .sidebar-title {
                      &.active {
                        ~ul {
                          display: block;
                        }
                      }
                    }
                  }
                }
              }
            }

            .sidebar-main {
              .sidebar-links {
                margin-top: 28px;
              }
            }

            &:hover {
              .sidebar-main {
                .sidebar-links {
                  margin-top: 0;

                  .menu-box {
                    >ul {
                      >li {
                        &.mega-menu {
                          .sidebar-title.active {
                            ~.mega-menu-container {
                              display: block !important;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .badge {
              opacity: 0;
              width: 0;
              padding: 0;
            }

            .sidebar-main {
              .sidebar-links {
                padding: unset;

                .menu-box {
                  margin-left: 15px;
                  margin-right: 15px;

                  >ul {
                    >li {

                      >ul,
                      .sidebar-submenu {
                        a {
                          display: none;
                        }
                      }

                      &.mega-menu {
                        .sidebar-title.active {
                          ~.mega-menu-container {
                            display: none !important;
                          }
                        }
                      }
                    }
                  }

                  >li {
                    max-width: 86px;
                    margin: 0;
                    transition: all 0.3s ease;

                    >ul,
                    .sidebar-submenu {
                      a {
                        display: none;
                      }
                    }

                    a {
                      padding: 12px 14px;
                      transition: all 0.3s ease;
                    }
                  }
                }
              }
            }

            .sidebar-main-title {
              display: none !important;
              opacity: 0;
              transition: all 0.3s ease;
            }

            .logo-wrapper {
              display: none;
            }

            .logo-icon-wrapper {
              text-align: right;
              display: block;
            }

            &:hover {
              width: 270px;
              transition: all 0.3s ease;

              .badge {
                opacity: 1;
                width: auto;
                transition: all 0.3s ease;
              }

              .sidebar-main {
                .sidebar-links {
                  padding: 20px;

                  .menu-box {
                    margin-right: unset;
                    margin-left: unset;

                    >ul {
                      >li {

                        >ul,
                        .sidebar-submenu {
                          a {
                            display: block;
                          }
                        }
                      }
                    }
                  }

                  >li {
                    transition: all 0.3s ease;

                    >ul,
                    .sidebar-submenu {
                      a {
                        display: block;
                      }
                    }

                    >a {
                      padding: 12px 15px;
                      transition: all 0.3s ease;

                      span {
                        display: inline-block;
                      }
                    }

                    a {
                      span {
                        display: inline-block;
                      }
                    }
                  }
                }
              }

              .sidebar-main-title {
                opacity: 1;
                display: block !important;
                transition: all 0.3s ease;
              }

              .logo-wrapper {
                display: block;
              }

              .logo-icon-wrapper {
                display: none;
              }
            }

            .mobile-sidebar {
              transform: translate(183px);
            }

            ~.page-body,
            ~footer {
              margin-left: 106px;
              transition: 0.5s;
            }

            ~.footer-fix {
              width: calc(100% - 90px);
            }

            .sidebar-main {
              .sidebar-links {
                height: calc(100vh - 100px);

                >li {
                  .sidebar-link {
                    &::before {
                      right: 20px;
                      left: unset;
                    }
                  }

                  a {
                    span {
                      display: none;
                    }
                  }
                }

                .sidebar-list {
                  .sidebar-title {
                    &.active {

                      ~ul,
                      .sidebar-submenu {
                        display: none;
                      }
                    }
                  }
                }
              }
            }

            .according-menu {
              display: none;
            }
          }

          .sidebar-main {

            .left-arrow,
            .right-arrow {
              display: none;
            }

            .sidebar-links {
              height: $sidebar-height;
              left: 0;
              z-index: 99;
              transition: color 1s ease;
              overflow: auto;
              color: rgba(0, 0, 0, 0);
              padding: 40px;
              font-family: $font-rubik;

              li {
                a {
                  font-weight: 500;

                  span {
                    letter-spacing: 0.7px;
                    text-transform: capitalize;
                    // color: $white;
                    color: black;
                  }

                  &.link-nav {
                    .according-menu {
                      display: none;
                    }
                  }
                }

                &:last-child {
                  border-bottom: none;
                }
              }

              &.opennav {
                left: 0;
              }

              .back-btn {
                display: block;
                width: 100%;
                padding: 0;

                .mobile-back {
                  padding: 20px;
                  color: $theme-font-color;
                  font-weight: 700;
                  text-transform: uppercase;
                  border-bottom: 1px solid $light-semi-gray;
                  cursor: pointer;
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                }
              }

              .back-btn {
                display: none !important;
              }

              .menu-box {
                background-color: rgba($white, 0.07);
                // background-color: rgba(#f6f8fc, 1);
                // border: 1px solid gray;

                padding-top: 20px;
                padding-bottom: 40px;
                border-radius: 10px;
                margin-bottom: 30px;

                &:last-child {
                  margin-bottom: unset;
                }

                >ul {
                  >li {
                    display: block;
                    width: 100%;
                    position: relative;
                    padding: 3px 0px;

                    &:hover {
                      .sidebar-link:not(.active) {
                        &:hover {
                          svg {
                            fill: rgba($primary-color, 0.1%);
                            // stroke: $white;
                            stroke: black;
                            transition: all 0.3s ease;
                          }

                          .sub-arrow {
                            svg {
                              // fill: $white;
                              fill: black;
                            }
                          }

                          span {
                            // color: $white;
                            color: black;
                            transition: all 0.3s ease;
                          }

                          .according-menu {
                            i {
                              // color: $white;
                              color: black;
                              transition: all 0.3s ease;
                            }
                          }
                        }
                      }
                    }

                    a {
                      padding: 12px 25px;
                      position: relative;
                      // color: $white;
                      color: black;
                      transition: all 0.3s ease;

                      svg {
                        width: 18px;
                        height: 24px;
                        margin-right: 10px;
                        vertical-align: middle;
                        float: none;
                        stroke-width: 2.3px;
                        // color: $white;
                        color: black;
                        transition: all 0.3s ease;
                      }

                      .sub-arrow {
                        right: 17px;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);

                        svg {
                          width: 13px;
                          height: 13px;
                        }

                        i {
                          display: none;
                        }

                        &:before {
                          display: none;
                        }
                      }
                    }

                    >ul,
                    .sidebar-submenu {
                      width: 100%;
                      padding: 0;
                      position: relative !important;

                      >li {
                        a {
                          padding-left: 40px !important;
                          font-weight: 400;
                        }
                      }

                      .submenu-title {
                        .according-menu {
                          top: 7px;
                        }
                      }

                      &.opensubmenu {
                        display: block;
                      }

                      li {
                        &:hover {
                          >a {
                            color: $white;
                            transition: all 0.3s ease;

                            &:after {
                              border-top: 2px solid $white;
                              transition: all 0.3s ease;
                            }
                          }
                        }

                        >ul,
                        .nav-sub-childmenu {
                          position: relative !important;
                          right: 0;
                          width: 100%;
                          padding: 0;

                          &.opensubchild {
                            display: block;
                          }

                          li {
                            a {
                              padding: 6px 45px;
                              padding-left: 56px !important;

                              &:after {
                                display: none;
                              }
                            }
                          }
                        }

                        a {
                          padding: 7px 25px;
                          font-size: 14px;
                          // color: $white;
                          color: black;
                          display: block;
                          position: relative;
                          letter-spacing: 0.06em;
                          font-weight: 500;
                          transition: all 0.3s ease;
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          text-transform: capitalize;

                          &.active {
                            span {
                              color: $secondary-color;
                            }

                            .sub-arrow {
                              svg {
                                transform: rotate(90deg);
                                fill: $secondary-color;
                              }
                            }
                          }

                          &.active,
                          &:hover {
                            color: $secondary-color;

                            &:after {
                              border-color: $secondary-color;
                            }
                          }

                          &:after {
                            left: 20px;
                            @extend %sidebar-after;
                          }

                          &:hover {
                            margin-left: 0;
                          }
                        }
                      }
                    }

                    // Recursion Css Start //
                    >.sidebar-submenu {

                      &,
                      li>.sidebar-submenu {
                        height: 0;
                        opacity: 0;
                        visibility: hidden;
                      }
                    }

                    &.active {
                      >.sidebar-submenu {

                        &,
                        li.active>.sidebar-submenu {
                          height: auto;
                          opacity: 1;
                          visibility: visible;
                        }
                      }
                    }

                    // Recursion Css End //

                    &.mega-menu {
                      .sidebar-title {
                        &.active {
                          ~.mega-menu-container {
                            display: block !important;
                          }
                        }
                      }
                    }

                    .mega-menu-container {
                      padding: 0;
                      position: relative;

                      &.opensubmenu {
                        display: block;
                      }

                      .mega-box {
                        width: 100%;
                        padding: 0;

                        .link-section {
                          &:hover {
                            h5 {
                              color: $white !important;
                              transition: all 0.3s ease;

                              &:after {
                                border-top: 2px solid $white;
                                transition: all 0.3s ease;
                              }
                            }
                          }

                          .submenu-title {
                            position: relative;

                            h5 {
                              margin-bottom: 0;
                              line-height: 1.9;
                              padding: 6px 25px 6px 45px;
                              font-size: 14px;
                              position: relative;
                              color: $white;
                              display: block;
                              letter-spacing: 0.06em;
                              font-weight: 500;
                              cursor: pointer;

                              &:after {
                                left: 25px;
                                @extend %sidebar-after;
                              }
                            }

                            .according-menu {
                              top: 8px;
                            }
                          }

                          .submenu-content {
                            &.opensubmegamenu {
                              li {
                                a {
                                  margin-bottom: 0;
                                  line-height: 1.9;
                                  padding: 6px 25px 6px 45px;
                                  font-size: 14px;
                                  position: relative;
                                  color: $white;
                                  display: block;
                                  letter-spacing: 0.06em;
                                  font-weight: 500;

                                  &:after {
                                    display: none;
                                  }

                                  &.active {
                                    color: $white;
                                  }
                                }
                              }
                            }

                            ul {
                              li {
                                a {
                                  line-height: 1.9;

                                  &:hover {
                                    margin-left: 0;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }

                    .sidebar-link {
                      transition: all 0.5s ease;
                      display: flex;
                      border-left: 2px solid transparent;
                      border-radius: 5px;

                      &.active,
                      &:hover {
                        transition: all 0.5s ease;
                        position: relative;
                        // background-color: lighten($primary-color, 10%);
                        background-color: #d1d1d2;
                        // border-color: $secondary-color;
                        border-color: #59667a;

                        .according-menu {
                          i {
                            color: $secondary-color;
                          }
                        }

                        svg {
                          color: $secondary-color;
                          stroke: $secondary-color;
                          transition: all 0.3s ease;
                        }

                        .sub-arrow {
                          svg {
                            transform: rotate(90deg);
                            transform-origin: center;
                          }
                        }

                        span {
                          color: $secondary-color;
                          transition: all 0.3s ease;
                        }
                      }

                      &:hover {
                        .sub-arrow {
                          svg {
                            transform: rotate (0);
                            transform-origin: center;
                          }
                        }
                      }
                    }

                    .sub-menu {
                      height: 0;
                      opacity: 0;
                      visibility: hidden;
                      transition: all 0.4s;
                    }

                    .sidebar-link.active+.sub-menu {
                      height: unset;
                      opacity: 1;
                      visibility: visible;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .mega-box {
        &.col {
          flex-basis: unset;
        }
      }

      .according-menu {
        position: absolute;
        right: 28px;
        top: 13px;
        display: block;

        i {
          vertical-align: middle;
          color: $white;
          font-weight: 600;
        }
      }

      .main-menu {
        &.border-section {
          border: none;
        }

        .menu-left {
          .main-menu-right {
            .toggle-nav {
              position: absolute;
              z-index: 2;
              right: 145px;
              top: 6px;

              .sidebar-name {
                font-size: 18px;
                padding-left: 5px;
                text-transform: uppercase;
              }
            }
          }
        }
      }

      .page-body {
        min-height: calc(100vh - 95px);
        margin-top: 95px;
        padding-top: 30px;
        margin-left: 320px;
      }
    }
  }

  .sidebar-wrapper {
    &.close_icon {
      &:hover {
        .simplebar-mask {
          top: 100px;
        }
      }

      .simplebar-mask {
        top: 120px;
      }
    }
  }
}

@keyframes pagebody {
  from {
    opacity: 0;
    transform: scale3d(0.98, 0.98, 0.98) translate(-20px, -50px);
  }

  50% {
    opacity: 1;
  }
}

@media screen and (max-width: 1480px) {
  .page-wrapper {
    &.horizontal-wrapper {
      .page-body-wrapper {
        .sidebar-wrapper {
          .sidebar-main {
            .sidebar-links {
              >li {
                .mega-menu-container {
                  right: -18%;
                  width: 91%;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1460px) {
  .page-wrapper {
    &.horizontal-wrapper {
      .page-body-wrapper {
        &.sidebar-wrapper {
          .sidebar-main {
            .sidebar-links {
              >li {
                .mega-menu-container {
                  right: -54%;
                  width: 75%;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1366px) {
  .page-wrapper {
    &.horizontal-wrapper {
      .page-body-wrapper {
        &.sidebar-wrapper {
          .sidebar-main {
            .sidebar-links {
              >li {
                .mega-menu-container {
                  right: -49%;
                  width: 70%;
                }
              }
            }
          }
        }
      }
    }
  }

  .profile-dropdown {
    &.onhover-show-div {
      right: 0;
      left: unset !important;

      &:before,
      &:after {
        right: 10px !important;
        left: unset !important;
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .page-wrapper {
    .page-header {
      .header-wrapper {
        .nav-right {
          >ul {
            >li {
              padding: 6px;
            }
          }
        }
      }
    }
  }

  .left-header .mega-menu-container .submenu-title {
    margin: 20px 20px 0 20px;
  }

  .mobile-title {
    &.d-none {
      display: flex !important;
      justify-content: space-between;
      padding: 20px 30px 17px;
      border-bottom: 1px solid #ddd;
      margin: 0 -15px;

      h5 {
        color: $white;
      }

      svg {
        // stroke: $white !important;
        stroke: black;
      }
    }
  }

  .page-wrapper {
    &.horizontal-wrapper {
      .page-body-wrapper {
        &.sidebar-wrapper {
          .sidebar-main {
            .sidebar-links {
              >li {
                .mega-menu-container {
                  right: -35%;
                  width: 56%;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .page-header {
    .header-wrapper {
      .nav-right {
        .notification-dropdown {
          top: 46px;
        }
      }
    }
  }

  .responsive-btn {
    display: none;
  }

  .page-wrapper {
    &.compact-wrapper {

      .page-body-wrapper {
        div.sidebar-wrapper {
          width: 318px;

          &.close_icon {
            &:hover {
              width: 110px;

              .sidebar-main {
                .sidebar-links {
                  padding: 12px;
                }
              }
            }
          }

          .logo-wrapper {
            padding: 4px 2px;
          }

          .sidebar-main {
            .sidebar-links {
              padding: 28px;

              .menu-box {
                >ul {
                  >li {
                    a {
                      padding: 12px 24px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .page-wrapper {
    &.horizontal-wrapper {
      .page-body-wrapper {
        .sidebar-main {
          .sidebar-links {
            .simplebar-mask {
              top: 0;
            }

            >li {

              .sidebar-submenu {
                width: 200px;
                opacity: 0;
                visibility: hidden;
                border-radius: 5px;
                transition: all 0.5s ease;

                a {
                  .sub-arrow {
                    position: absolute;
                    right: 20px;
                    top: 5px;
                    color: $light-semi-font;
                    font-weight: 100;
                    transition: all 0.5s ease;
                    font-size: 15px;
                    top: 5px;
                    display: none !important;

                    svg {
                      width: 13px;
                      height: 13px;
                    }
                  }
                }

                li {
                  .nav-sub-childmenu {
                    display: none;
                    border-radius: 4px;
                  }

                  &:hover {
                    .nav-sub-childmenu {
                      display: block;
                      position: absolute;
                      left: 200px;
                      background-color: $white;
                      top: -10px;
                      padding: 15px 0px 15px 0px;
                      box-shadow: 0 0 1px 0 #898989;
                    }
                  }

                  a {
                    &:hover {
                      .sub-arrow {
                        right: 16px;
                        transition: all 0.5s ease;
                      }
                    }
                  }
                }
              }

              .mega-menu-container {
                opacity: 0;
                visibility: hidden;
                transition: all 0.5s ease;
              }

              &.hoverd {
                .sidebar-submenu {
                  opacity: 1;
                  visibility: visible;
                  margin-top: 8px;
                  transition: all 0.5s ease;
                }

                .mega-menu-container {
                  opacity: 1;
                  visibility: visible;
                  margin-top: 20px;
                  transition: all 0.5s ease;
                }
              }
            }

            .sub-arrow {
              display: none !important;
            }
          }
        }

        .toggle-nav {
          display: none;
        }

        .mobile-back {
          display: none !important;
        }
      }
    }
  }
}

@media screen and (max-width: 1469px) and (min-width: 1200px) {
  .footer {
    margin-left: 285px;
  }

  .page-wrapper {
    &.compact-wrapper {
      .page-header {
        margin-left: 285px;
        width: calc(100% - 285px);

        &.close_icon {
          margin-left: 106px;
        }
      }

      .page-body-wrapper {
        .page-body {
          margin-left: 285px;
        }

        div.sidebar-wrapper {
          width: 285px;

          &.close_icon {
            &:hover {
              width: 110px;

              .sidebar-main {
                .sidebar-links {
                  padding: 12px;
                }
              }
            }
          }

          .logo-wrapper {
            padding: 4px 2px;
          }

          .sidebar-main {
            .sidebar-links {
              padding: 28px;

              .menu-box {
                >ul {
                  >li {
                    a {
                      padding: 12px 24px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .page-wrapper {
    .page-header {
      .header-wrapper {
        .nav-right {
          .profile-dropdown.onhover-show-div {
            top: 42px;
            width: 160px;
          }
        }
      }
    }
  }

  .page-wrapper {
    .page-header {
      .header-wrapper {
        .nav-right {
          ul {
            li {
              .d-flex {
                img {
                  width: 30px;
                }
              }
            }
          }

          >ul {
            >li {
              .badge {
                font-size: 10px;
              }
            }
          }
        }
      }
    }

    &.horizontal-wrapper {
      .page-body-wrapper {
        .sidebar-wrapper {
          top: 67px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .sidebar-wrapper {
    .menu {
      .brand-logo {
        img {
          width: 120px;
        }
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .page-wrapper {
    &.compact-wrapper {
      .page-body-wrapper {
        div.sidebar-wrapper {
          .logo-wrapper {
            padding: 17px 20px !important;

            .back-btn {
              right: 22px !important;
            }
          }

          .sidebar-main {
            .sidebar-links {
              padding: 30px 26px !important;

              .menu-box {
                >ul {
                  >li {
                    a {
                      padding: 12px 14px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .toggle-nav {
    i {
      font-size: 20px;
    }
  }
}

/**=====================
    78. Sidebar CSS Ends
==========================**/