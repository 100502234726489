/**=====================
     47. Footer CSS Start
==========================**/
.footer {
  background-color: $footer_bg_color;
  box-shadow: $footer_box_shadow;
  padding: 20px;
  bottom: 0;
  left: 0;
  margin-left: 320px;
  transition: 0.5s;
  &.footer-dark {
    background-color: $footer_dark_color;
    p {
      color: $white;
    }
  }

  .footer-left {
    display: flex;
    align-items: center;
  }
  .footer-right {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    ul.color-varient {
      display: flex;
      justify-content: flex-end;
      li {
        width: 22px;
        height: 22px;
        border-radius: 25px;
        display: inline-block;
        &:first-child {
          background-color: $primary-color;
        }
        &:nth-child(2) {
          background-color: $secondary-color;
        }
        &:nth-child(3) {
          background-color: $info-color;
        }
        &:nth-child(4) {
          background-color: $danger-color;
        }
        &:nth-child(n + 2) {
          margin-left: -13%;
        }
      }
    }
  }
}
.footer-fix {
  width: calc(100% - 320px);
  position: fixed;
}
@media (max-width: 767px) {
  // footer
  .footer {
    .footer-right {
      ul {
        &.color-varient {
          li {
            width: 25px;
            height: 25px;
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .footer {
    text-align: center;
    .footer-left,
    .footer-right {
      justify-content: center;
    }
    .footer-right {
      margin-top: 10px;
      margin-right: -15px;
      ul {
        &.color-varient {
          li {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }
}
@media (max-width: 480px) {
  .footer {
    p {
      font-size: 12px;
    }
    .footer-right {
      margin-top: 0;
      ul {
        &.color-varient {
          display: none;
          li {
            width: 15px;
            height: 15px;
          }
        }
      }
    }
  }
}
/**=====================
     47. Footer CSS Ends
==========================**/
